<template>
  <div class="auth-page">
    <div class="w-100">
      <!-- head -->
      <div class="text-center">
        <img
          class="cursor-pointer"
          @click="$router.push('/')"
          :src="require('@/assets/images/global/logo-word.svg')"
          alt=""
        />
        <span class="font-24 icon-black--text d-block mt-4"
          >Enter your new password</span
        >
      </div>

      <!-- content -->
      <div class="auth-page__content radius-15 pt-8 pb-4 px-5 mt-7">
        <v-form
          v-model="valid"
          ref="passwordForm"
          @submit.prevent="submitHandler"
        >
          <!-- email -->
          <div>
            <span class="d-block font-16 icon-black--text">New password</span>
            <base-input
              v-model="user.password"
              type="password"
              placeholder="*********"
            ></base-input>
          </div>

          <div>
            <span class="d-block font-16 icon-black--text"
              >Confirm password</span
            >
            <base-input
              v-model="user.confirmPassword"
              type="password"
              placeholder="*********"
            ></base-input>
          </div>

          <!-- btn -->
          <v-btn
            class="mt-6 radius-15"
            height="45px"
            color="primary"
            elevation="0"
            block
            type="submit"
            ><span class="white--text font-16">Reset</span></v-btn
          >
        </v-form>
      </div>

      <!-- bottom btn -->
      <div
        class="primary-border px-5 py-3 text-center mt-6 radius-15 hover-btn d-flex align-center justify-center"
      >
        <span class="font-16 icon-black--text d-inline-block mr-1"
          >New to Bisni?
        </span>
        <router-link to="/signup" class="d-flex">
          <span class="font-16 blue--text font-500">Create an account.</span>
        </router-link>
      </div>

      <div
        class="primary-border px-5 py-3 text-center mt-6 radius-15 hover-btn"
        @click="$router.push('/plans')"
      >
        <span class="font-16 icon-black--text font-16"
          >See Prices and plans
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    user: {
      password: "",
      confirmPassword: "",
    },
  }),
  computed: {},
  methods: {
    async submitHandler() {
      // validate password
      if (this.user.password !== this.user.confirmPassword) {
        this.$store.dispatch("showSnack", {
          text: "Password does not match",
          color: "error",
        });
        return;
      }

      const token = this.$route.query.token;
      const res = await this.$axios.post(
        `${process.env.VUE_APP_VERIFY_PASSWORD}?token=${token}`,
        {
          newPassword: this.user.password,
        }
      );

      if (res.data) {
        // this.$store.dispatch("setUser", res.data);
        this.$store.dispatch("showSnack", {
          text: res.data.message || "Password changed successfully",
          color: "success",
        });

        setTimeout(() => {
          this.$router.push("/signin");
        }, 1000);
      } else {
        this.$store.dispatch("showSnack", {
          text: res.message,
          color: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.auth-page {
  max-width: 380px;
  width: 100%;
  position: relative;
  padding: 4% 0px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  &__content {
    background: $bg-grey;
  }
}
</style>
